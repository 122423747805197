import http from "../http-common";

class BGDBDataService {
  searchGame(type, query, params) {
    return http.get(`/bgdb/search?type=${type}&query=${query}&${params}`);
  }

  discover() {
    return http.get(`/bgdb/discover`);
  }
}

export default new BGDBDataService();
