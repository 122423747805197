import React, { useState, useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import GameDataService from "../../services/game.service";
import LocationDataService from "../../services/location.service";
import CategoryDataService from "../../services/category.service";
import moment from "moment";
import AlertDialog from "../../components/AlertDialog";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { replaceEmptyString } from "../../helpers/replaceEmptyString";
import { useAlertMessageStore, useHeaderStore } from "src/store/store";
import Loading from "src/components/Loading";
import GameForm from "src/components/game/gameForm";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    display: "block",
    margin: "auto",
  },
  search: {
    marginBottom: "10px",
    background: "white",
    border: "1px solid black",
  },
  picture: {
    "& img": {
      height: "10vh",
    },
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const EditGame = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { game_id } = useParams();
  const collection_id = localStorage.getItem("collectionID");

  const [game, setGame] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [maingameList, setMaingameList] = useState([]);
  const [metaInfo, setMetaInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteGame, setDeleteGame] = useState(false);
  const [archiveGame, setArchiveGame] = useState(false);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);

  useEffect(() => {
    useHeaderStore.setState({
      title: "game.modifyGame_one",
      help: "help.modifyGame",
    });

    const fetchGameDetails = async (id) => {
      try {
        const response = await GameDataService.get(id);
        const gameData = response.data.data.game;
        setGame({
          name: gameData.name,
          ean: gameData.ean || "",
          designer: gameData.designer || "",
          publisher: gameData.publisher || "",
          minPlayers: gameData.min_players || "",
          maxPlayers: gameData.max_players || "",
          minAge: gameData.min_age || "",
          playtime: gameData.playtime || "",
          yearpublished: gameData.yearpublished || "",
          coop: gameData.coop || false,
          recordPoints: gameData.recordPoints || false,
          maingame:
            response.data.data.maingame.id && response.data.data.maingame.name
              ? response.data.data.maingame
              : null,
          rating: 0,
          description: "",
          categoryList: response.data.data.categories || [],
          location:
            response.data.data.location.id && response.data.data.location.name
              ? response.data.data.location
              : null,
          image: gameData.image || "",
          bgdbID: gameData.bgdb_id || "",
          collectionID: collection_id,
          addMultiple: false,
          archived: gameData.archived,
          datePurchased: moment(gameData.date_purchased).isValid()
            ? moment(gameData.date_purchased).format("YYYY-MM-DD")
            : null,
        });
        setMetaInfo(response.data.meta);
      } catch (error) {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Laden des Spiels",
          },
        });
      } finally {
        setLoading(false);
      }
    };

    const retrieveCategories = () => {
      CategoryDataService.getAll()
        .then((response) => {
          setCategoryList(response.data);
        })
        .catch((e) => {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: "Fehler beim Laden der Kategorien",
            },
          });
        });
    };

    const retrieveLocations = () => {
      LocationDataService.getAll(collection_id)
        .then((response) => {
          setLocationList(response.data);
        })
        .catch((e) => {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: "Fehler beim Laden der Standorte",
            },
          });
        });
    };

    const retrieveMainGames = () => {
      GameDataService.getMainGames(collection_id)
        .then((response) => {
          setMaingameList(response.data.data);
        })
        .catch((e) => {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "error",
              message: "Fehler beim Laden der Hauptspiele",
            },
          });
        });
    };

    fetchGameDetails(game_id);
    retrieveCategories();
    retrieveLocations();
    retrieveMainGames();
  }, [game_id, collection_id]);

  const handleFormSubmit = (values) => {
    const data = replaceEmptyString(values, null);
    setLoading(true);
    GameDataService.update(game_id, { game: data }, collection_id)
      .then((response) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "success",
            message: `Das Spiel wurde erfolgreich gespeichert`,
          },
        });
        history.push(`/games/${response.data.id}`);
      })
      .catch((error) => {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Speichern des Spiels",
          },
        });
      }).finally(() => {
        setLoading(false)
      });
  };

  const handleDeleteGame = () => setDeleteGame(true);

  const handleArchiveGame = () => setArchiveGame(true);

  const handleChangeSpeedDial = () => setOpenSpeedDial(!openSpeedDial);

  const handleDialogClose = () => setDeleteGame(false);

  const handleDialogConfirm = async () => {
    try {
      await GameDataService.delete(game_id, collection_id, game.name);
      setDeleteGame(false);
      props.setStateValue("alertMessage", {
        severity: "success",
        message: "Das Spiel wurde erfolgreich gelöscht",
        key: Math.random(),
      });
      history.push(`/`);
    } catch (error) {
      setDeleteGame(false);
      useAlertMessageStore.setState({
        alertMessage: {
          severity: "error",
          message: "Fehler beim Löschen des Spiels",
        },
      });
    }
  };

  const handleArchiveConfirm = async () => {
    try {
      const data = {
        id: game_id,
        title: game.name,
        collection_id,
        archive: !game.archived,
      };
      await GameDataService.archive(data);
      setArchiveGame(false);
      const message = game.archived ? "verschoben" : "archiviert";
      props.setStateValue("alertMessage", {
        severity: "success",
        message: `Das Spiel wurde erfolgreich ${message}`,
        key: Math.random(),
      });
      history.push(`/`);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!loading) {
    return (
      <>
        {deleteGame && (
          <AlertDialog
            title={`${game.name} wirklich löschen?`}
            description="Es werden alle Statistiken und Historien in Verbindungen mit diesem Spiel gelöscht."
            cancel="Abbrechen"
            confirm="Löschen"
            handleDialogClose={handleDialogClose}
            handleDialogConfirm={handleDialogConfirm}
          />
        )}

        {archiveGame && (
          <AlertDialog
            title={
              game.archived
                ? `${game.name} wirklich verschieben?`
                : `${game.name} wirklich archivieren?`
            }
            description={
              game.archived
                ? "Das Spiel wird in die aktive Sammlung verschoben."
                : "Das Spiel wird in das Archiv verschoben. Alle Statistiken und Historien in Verbindungen mit diesem Spiel bleiben erhalten."
            }
            cancel="Abbrechen"
            confirm={game.archived ? "Verschieben" : "Archivieren"}
            handleDialogClose={() => setArchiveGame(false)}
            handleDialogConfirm={handleArchiveConfirm}
          />
        )}
        <GameForm
          handleFormSubmit={handleFormSubmit}
          initialValues={game}
          maingameList={maingameList}
          locationList={locationList}
          categoryList={categoryList}
          submitText="Spiel speichern"
          action="edit"
        />

        <SpeedDial
          ariaLabel="Spielmenü"
          className={classes.speedDial}
          icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
          open={openSpeedDial}
          onOpen={handleChangeSpeedDial}
          onClose={handleChangeSpeedDial}
        >
          {(metaInfo.privileges.game_delete || metaInfo.privileges.owner) && (
            <SpeedDialAction
              key="Löschen"
              icon={<DeleteIcon color="error" />}
              tooltipTitle="Löschen"
              onClick={handleDeleteGame}
            />
          )}
          <SpeedDialAction
            key="Archivieren"
            icon={
              game.archived ? (
                <UnarchiveIcon color="primary" />
              ) : (
                <ArchiveIcon color="primary" />
              )
            }
            tooltipTitle={game.archived ? "Verschieben" : "Archivieren"}
            onClick={handleArchiveGame}
          />
        </SpeedDial>
      </>
    );
  }
};

export default EditGame;
