import "../../css/App.css";
import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import AlertBox from "../../components/AlertBox";
import { Link } from "react-router-dom";
import AlertDataService from "../../services/alert.service";
import CategoryDataService from "../../services/category.service";
import GameDataService from "../../services/game.service";
import AddIcon from "@mui/icons-material/Add";
import { handleResponseAxios } from "../../helpers/handle-response";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGameSearchStore, useAlertMessageStore } from '../../store/store';
import { GameCard } from "src/components/game";


import {
  Grid,
  Box,
  Typography,
  Chip,
  Stack,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  LinearProgress,
} from '@mui/material';


const useStyles = (theme) => ({
  scroll: {
    display: "grid",
    [theme.breakpoints.between("md", "xl")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  badge: {
    transform: "rotate(-45deg) translate(0,50%)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  categoryFilter: {
    marginBottom: theme.spacing(2),
    overflowX: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
  },
});


class Home extends Component {
  constructor(props) {
    super(props);

    this.handleChangeSpeedDial = this.handleChangeSpeedDial.bind(this);
    this.retrieveGameList = this.retrieveGameList.bind(this);
    this.retrieveCategories = this.retrieveCategories.bind(this);
    this.handleCategoryFilterClick = this.handleCategoryFilterClick.bind(this);
    this.retrieveAlerts = this.retrieveAlerts.bind(this);


    this.props.setStateValue("isLoading", true);
    this.props.setStateValue("pageTitle", null);
    this.props.setStateValue('help', "home");

    this.state = {
      gameList: [],
      metaInfo: [],
      categories: [],
      gameSearch: useGameSearchStore.getState(),
      isInit: true,
      openSpeedDial: false,
      collectionRole: "",
      hasMore: true,
      page: 1,
      collectionID: localStorage.getItem("collectionID"),
      missingCollection: false,
      alerts: {},
    };

    this.limit = 50;
    this.timer = null;
  }

  componentDidMount() {
    this.unsub = useGameSearchStore.subscribe((gameSearch) => {
      this.setState({gameSearch})
   }, (state) => state);
    this.retrieveAlerts();
    this.retrieveGameList(
      this.state.gameSearch.searchString,
      this.state.gameSearch.categoryFilter,
      this.state.collectionID,
      this.state.page,
      this.limit,
      0
    );
    this.retrieveCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.gameSearch !== this.state.gameSearch ||
      prevProps.location.key !== this.props.location.key
    ) {
      this.setState({
        collectionID: localStorage.getItem("collectionID"),
        gameList: [],
        hasMore: true,
        page: 1,
      });
      this.props.setStateValue("isLoading", true);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.retrieveGameList(
          this.state.gameSearch.searchString,
          this.state.gameSearch.categoryFilter,
          localStorage.getItem("collectionID"),
          1,
          this.limit,
          0
        );
      }, 100);
    }
  }

  retrieveGameList(
    name = this.state.gameSearch.searchString,
    categoryFilter = this.state.gameSearch.categoryFilter,
    collectionID = this.state.collectionID,
    page = this.state.page,
    limit = this.limit,
    archive = 0
  ) {
    if (!collectionID || collectionID === null || collectionID === "null") {
      this.setState({
        missingCollection: true,
      });
    } else {
      this.setState({
        missingCollection: false,
      });
      this.props.setStateValue("isLoading", true);
      GameDataService.findByName(
        name,
        categoryFilter,
        collectionID,
        page,
        limit,
        archive
      )
        .then((response) => {
          if (page > 1) {
            this.setState({
              gameList: this.state.gameList.concat(response.data.data),
              metaInfo: response.data.meta,
              isInit: false,
            });
            this.props.setStateValue("isLoading", false);
          } else {
            this.setState({
              gameList: response.data.data,
              metaInfo: response.data.meta,
              isInit: false,
            });
            this.props.setStateValue("isLoading", false);
          }
          if (response.data.data.length === 0) {
            this.setState({
              hasMore: false,
            });
          } else {
            this.setState({
              page: this.state.page + 1,
            });
          }
        })
        .catch((e) => {
          if(e.handled) {
            this.props.setStateValue("isLoading", false);
          } else {
            useAlertMessageStore.setState({ alertMessage: { severity: "error", message: `error.2201` } })
            this.props.setStateValue("isLoading", false);
          }
        });
    }
  }

  retrieveCategories() {
    CategoryDataService.getAll()
      .then((response) => {
        this.setState({
          categories: response.data,
        });
      })
      .catch((e) => {
        handleResponseAxios(e.response);
      });
  }

  retrieveAlerts() {
    AlertDataService.getAll()
      .then((response) => {
        this.setState({
          alerts: response.data
        })
      })
      .catch((e) => {
        console.dir(e);
      });
  }

  handleCategoryFilterClick(categoryID) {
    useGameSearchStore.setState({categoryFilter: categoryID})
  }

  handleChangeSpeedDial() {
    this.setState({
      openSpeedDial: !this.state.openSpeedDial,
    });
  }

  render() {
    const {
      gameSearch,
      gameList,
      metaInfo,
      categories,
      isInit,
      openSpeedDial,
      hasMore,
      missingCollection,
      alerts,
    } = this.state;
    const { classes } = this.props;

    return (
      <Box className={classes.root} spacing={2}>
        {missingCollection ? (
          <h2>
            Du bist noch kein Mitglied einer Sammlung. Klicke{" "}
            <a href="/settings/collections/add-collection">hier</a> um eine neue Sammlung
            anzulegen.
          </h2>
        ) : isInit ? (
          <img
            alt="logo"
            style={{
              width: "auto",
              maxWidth: "80vw",
              height: "auto",
              maxHeight: "100vh",
              display: "block",
              margin: "auto",
              gridColumn: "span 3",
            }}
            src="../logo512.png"
          />
        ) : metaInfo.totalGames > 0 && gameList.length > 0 ? (
          <>
            <Stack
              direction="row"
              spacing={1}
              className={classes.categoryFilter}
            >
              {categories.map((category, key) => {
                return (
                  <Chip
                    color="primary"
                    label={category.name}
                    key={category.id}
                    variant={
                      gameSearch.categoryFilter === category.id ? "filled" : "outlined"
                    }
                    onClick={() => this.handleCategoryFilterClick(category.id)}
                    onDelete={
                      gameSearch.categoryFilter === category.id
                        ? () => this.handleCategoryFilterClick("")
                        : null
                    }
                  />
                );
              })}
            </Stack>
            {alerts.length > 0 &&
              <AlertBox alerts={alerts} />
            }
            {(gameSearch.searchString !== "" || gameSearch.categoryFilter !== "") && (
              <Box m={2}>
                <Typography align="center">
                  Suchergebnis: {metaInfo.searchResults} / {metaInfo.totalGames}{" "}
                  Spielen{" "}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={(metaInfo.searchResults / metaInfo.totalGames) * 100}
                />
              </Box>
            )}

            <InfiniteScroll
              dataLength={gameList.length} //This is important field to render the next data
              next={this.retrieveGameList}
              hasMore={hasMore}
              scrollThreshold={0.9}
              style={{minHeight: '50vh'}}
            >
              {" "}
              <Grid container spacing={3}>
              {gameList.map((game) => {
                return (
                  <Grid item sx={{ py: '12px' }} xs={12} sm={6} lg={4} xl={3} xxxl={3} key={game.id}>
                    <GameCard name={game.name} id={game.id} rating={game.rating} location={game.location} lastPlay={game.last_match} minAge={game.min_age} minPlayers={game.min_players} maxPlayers={game.max_players} playtime={game.playtime} profilePicture={game.image} currentLend={game.current_lend} />
                  </Grid>
                );
              })}
              </Grid>
            </InfiniteScroll>
          </>
        ) : metaInfo.totalGames > 0 && gameList.length === 0 ? (
          <>
            <Stack
              direction="row"
              spacing={1}
              className={classes.categoryFilter}
            >
              {categories.map((category, key) => {
                return (
                  <Chip
                    color="primary"
                    label={category.name}
                    variant={
                      gameSearch.categoryFilter === category.id ? "filled" : "outlined"
                    }
                    onClick={() => this.handleCategoryFilterClick(category.id)}
                    onDelete={
                      gameSearch.categoryFilter === category.id
                        ? () => this.handleCategoryFilterClick("")
                        : null
                    }
                  />
                );
              })}
            </Stack>
            <h2>Kein passendes Spiel gefunden</h2>
          </>
        ) : (
          metaInfo.totalGames === 0 && (
            <h2>Die Sammlung enthält noch kein Spiel</h2>
          )
        )}
        {(metaInfo.privileges?.owner === 1||
          metaInfo.privileges?.game_insert === 1) && (
          <SpeedDial
            ariaLabel="Spielmenü"
            className={classes.speedDial}
            icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
            open={openSpeedDial}
            onOpen={this.handleChangeSpeedDial}
            onClose={this.handleChangeSpeedDial}
          >
            <SpeedDialAction
              key="add"
              icon={<AddIcon color="primary" />}
              tooltipTitle="Spiel hinzufügen"
              component={Link}
              to="/add-game"
            />
          </SpeedDial>
        )}
      </Box>
    );
  }
}

export default withStyles(useStyles)(Home);
